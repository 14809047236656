.fincareloandepartment{
    background-color: aliceblue;
}



.btn-grad98 {
    background-image: linear-gradient(to right, #001ca6 0%, #7caacd  51%, #780a52  100%);
    margin: 1px;
    padding: 7px 22px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: rgb(255, 255, 255);            
    box-shadow: 0 0 10px #ffffff;
    border-radius: 14px;
    display: block;
  }
  
  .btn-grad98:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }