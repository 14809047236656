.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;
  
}

.textjustify{
  text-align: justify;
  text-justify: inter-word;
}

.container{
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}


.buttonapply{
  background-color: #b0820d; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 04px;
}

.backc{
  background-color: #a2afb8;
}

.colorb{
  background-color: #61dafb;
}


.shamolyfincare{
  background-color: #3e0db0; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 04px;
}


.texts{
  text-align: justify;
  text-justify: inter-word;
}

* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}


.shyamolyfincare{
  background-color: #fae9e3;
}