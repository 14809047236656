.loanservicenadia{
    background-color: rgb(32, 130, 215);
}


.homebutton{
    background-color: #041e8f; /* Green */
    border: none;
    color: rgb(246, 248, 248);
    padding: 7px 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 04px;
}


.backgropundphoto{
     
    height:  400px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/ZRQX6MgF/Back-photo.jpg);
    background-attachment: fixed;
    color: rgb(11, 135, 149);
    display: flex;
    justify-content: center;
    align-items: center;

  }


  .background2{
    background-color: #1f8fa3;
  }